import { makeStyles, createMuiTheme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5)
  },
  logout: {
    marginRight: theme.spacing(5),
    position: 'absolute'
  },
  input: {
    margin: theme.spacing(1),
    height: 40
  },
  text: {
    marginLeft: theme.spacing(1)
  },
  heading: {
    margin: theme.spacing(1),
    color: '#a5a5a5',
    fontWeight: 500
  },
  google: {
    backgroundColor: '#e35146',
    '&:hover': {
      backgroundColor: '#ca3d32'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151
  },
  card: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3)
  },
  spaced: {
    margin: theme.spacing(1)
  },
  premium: {
    borderRadius: '5px',
    backgroundColor: '#ffa700',
    color: '#fff'
  },
  canceled: {
    borderRadius: '5px',
    backgroundColor: '#ff0031',
    color: '#fff'
  },
  expired: {
    borderRadius: '5px',
    backgroundColor: '#a8a8a8',
    color: '#fff'
  },
  trial: {
    borderRadius: '5px',
    backgroundColor: '#6ab2ff',
    color: '#fff'
  },
  free: {
    borderRadius: '5px',
    backgroundColor: '#23d160',
    color: '#fff'
  },
  blocked: {
    borderRadius: '5px',
    backgroundColor: '#a8a8a8',
    color: '#fff'
  },
  deleted: {
    borderRadius: '5px',
    backgroundColor: '#ff0031',
    color: '#fff'
  },
  active: {
    borderRadius: '5px',
    backgroundColor: '#23d160',
    color: '#fff'
  },
  sites: {
    margin: theme.spacing(2)
  },
  deleteButton: {
    position: 'absolute',
    color: '#ff5f7e',
    margin: theme.spacing(.5)
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4e79ff'
    },
    secondary: {
      main: '#23d160',
      contrastText: '#fff'
    }
  }
})

export {
    useStyles,
    theme
}