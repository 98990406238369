import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, CircularProgress, Card, CardContent, CardMedia, Chip, Link, Snackbar, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useStyles, theme } from '../assets/styles/style'
import { Client } from '../libs/mbr-client';
import query from '../libs/query';
import Photo from '../assets/images/customer.png';
import { useCookies } from 'react-cookie';
import { Redirect } from "react-router-dom";


function delay(t, v) {
  return new Promise(function(resolve) { 
      setTimeout(resolve.bind(null, v), t)
  });
}

const Admin = () => {
  // hooks
  const classes = useStyles();
  const [,, removeCookie] = useCookies(['XSRF-TOKEN']);
  const [loading, setLoading] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const [user, setUser] = useState({});
  const [sites, setSites] = useState([]);
  const [show, setShow] = useState({display: 'none'});
  const [name, setName] = useState('');
  const [site, setSite] = useState('');
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success',
    vertical: 'top',
    horizontal: 'center'
  });
  const [blockButton, setBlockButton] = useState({});
  const vertical = alert.vertical
  const horizontal = alert.horizontal

  // auth
  const mbrClient = new Client("https://u.8b.com/api/v1/");

  // logging out

  function logOut () {
    setLoggedOut(true)
    removeCookie(['XSRF-TOKEN'])
  }

  if (loggedOut) {
    return <Redirect to='/login' />;
  }

  // handlers
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(prev => ({...prev, open: false }));
  };

  const handleError = (message) => {
    setLoading(false)
    switch (message) {
      case 'Access denied':
      case 'Invalid X-XSRF-Token':
      case 'Request failed with status code 403':
        setAlert(prev => ({...prev,
          open: true,
          message: message,
          severity: 'error'
        }))
        delay(2000).then(() => {
          setLoggedOut(true)
          removeCookie(['XSRF-TOKEN'])
        })
        break
      default:
        setAlert(prev => ({...prev,
          open: true,
          message: message,
          severity: 'error'
        }))
    }
  }

  // manage users
  const blockUser = user_id => () => {
    let iamready = window.confirm('Are you sure you want to block the user and all their sites?');
    if (!iamready) return;
    setLoading(true)
    mbrClient.ajax({
        method: 'PUT',
        url: `https://u.8b.com/api/v1/users/${user_id}`,
        data: { "status":"blocked" }
    })
    .then(() => {
        query(`/v1/users/${user_id}/sites`, { method: 'GET' })
        .then(function(sites) {
            let promiseArr = [];
            for (let i = 0; i < sites.items.length; i++){
                promiseArr.push(query(`/v1/sites/${ sites.items[i].id }`, { method: 'DELETE' }));
            }
            Promise.all(promiseArr)
            .then(() => {
              setLoading(false)
              setBlockButton({
                styles: {
                  backgroundColor: '#ffa700', color: '#fff'
                },
                action: unblockUser(user_id),
                text: 'Unblock user'
              })
              setAlert(prev => ({...prev,
                open: true,
                message: `The user was successfully blocked. All user's sites removed.`,
                severity: 'success'
              }))
              setUser(prev => ({...prev,
                status: 'blocked'
              }))
            })
            .catch(err => handleError(err.message));
        })
        .catch(err => handleError(err.message));
    })
    .catch(err => handleError(err.message));
  }


  const deleteSite = site_id => () => {
    let iamready = window.confirm('Are you sure you want to delete this site?');
    if (!iamready) return;
    setLoading(true)
    query(`/v1/sites/${ site_id }`, { method: 'DELETE' })
    .then(() => {
      setAlert(prev => ({...prev,
        open: true,
        message: `Site was successfully removed.`,
        severity: 'success'
      }))
      setLoading(false)
      let site = sites.filter(x => x.id === site_id)
      sites.splice(sites.indexOf(site[0]), 1);
      setSites(sites)
    })
    .catch(err => handleError(err.message));
  }


  const unblockUser = user_id => () => {
    setLoading(true)
    mbrClient.ajax({
      method: 'PUT',
      url: `https://u.8b.com/api/v1/users/${user_id}`,
      data: { "status":"active" }
    })
    .then(() => {
      setLoading(false)
      setAlert(prev => ({...prev,
        open: true,
        message: 'The user was successfully unblocked.',
        severity: 'success'
      }))
      setBlockButton({
        styles: {
          backgroundColor: '#ff0031', color: '#fff'
        },
        action: blockUser(user_id),
        text: 'Block user and delete sites'
      })
      setUser(prev => ({...prev,
        status: 'active'
      }))
    })
    .catch(err => handleError(err.message));
  }


  const deleteUser = user_id => () => {
    let iamready = window.confirm('Are you sure you want to delete the user?');
    if (!iamready) return;
    setLoading(true)
    mbrClient.ajax({
      method: 'DELETE',
      url: `https://u.8b.com/api/v1/users/${user_id}`
    })
    .then(() => {
      setLoading(false)
      setAlert(prev => ({...prev,
        open: true,
        message: `The user was successfully deleted. All user's sites will be removed soon.`
      }))
      setUser(prev => ({...prev,
        status: 'deleted'
      }))
    })
    .catch(err => handleError(err.message));
  }


  // search for users
  function getLicenseType (site) {
    if (!(site.licenses && site.licenses.length)) return 'free';
    return site.licenses.some((item) => item.status === 'active' && item.type === 'pay' && !item.isExpired) ? 'premium' :
           site.licenses.some((item) => item.status === 'active' && item.type === 'pay' && item.isExpired) ? 'expired' :
           site.licenses.some((item) => item.type === 'pay') ? 'canceled' : 'trial';
  }

  function nameChange (event) {
    setName(event.target.value)
  }

  function siteChange (event) {
    setSite(event.target.value)
  }

  function search (event) {
    // eslint-disable-next-line
    switch (event.type) {
      // eslint-disable-next-line
      case 'keydown':
        if (event.keyCode !== 13) break
        // eslint-disable-next-line
      case 'submit':
        event.preventDefault();
        // eslint-disable-next-line
      case 'click': 
        setLoading(true)
        // eslint-disable-next-line
        switch (true) {
          case event.target.id === 'name' || event.target.parentElement.id === 'name':
            if (name) {
            mbrClient.ajax({
                method: 'get',
                url: 'https://u.8b.com/api/v1/users?filter[q]=' + name
              })
              .then(res => {
                setLoading(false)
                if (res.items.length) {
                  setShow({display: 'block'})
                  const info = res.items[0]
                  const time = new Date(info.registerTime).toLocaleDateString()
                  const verified = info.verified.toString()
                  setUser({
                    id: info.id,
                    name: info.name,
                    email: info.email,
                    ip: info.registerIp,
                    service: info.registerServiceName,
                    time: time,
                    verified: verified,
                    status: info.status,
                    role: info.role
                  })
                  if (info.status === 'blocked') {
                    setBlockButton({
                      styles: {
                        backgroundColor: '#ffa700', color: '#fff'
                      },
                      action: unblockUser(info.id),
                      text: 'Unblock user'
                    })
                  } else {
                    setBlockButton({
                      styles: {
                        backgroundColor: '#ff0031', color: '#fff'
                      },
                      action: blockUser(info.id),
                      text: 'Block user and delete sites'
                    })
                  }
                  query('/v1/users/' + info.id + '/sites', { method: 'GET' })
                    .then(result => {
                      if (result.items.length) {
                        const Sites = []
                        for (let i = 0; i < result.items.length; i++) {
                          let license = getLicenseType(result.items[i])
                          Sites.push({
                            id: result.items[i].id,
                            url: result.items[i].publicUrl,
                            license: license
                          })
                        }
                        setSites(Sites)
                        Sites.some(x => x.license === 'premium') 
                        ? setUser(prev => ({...prev,
                          type: 'premium'
                        }))
                        : setUser(prev => ({...prev,
                          type: 'free'
                        }))
                      } else {
                        setSites([])
                        setUser(prev => ({...prev,
                          type: 'free'
                        }))
                      }
                    })
                    .catch(err => handleError(err.message));
                } else {
                  setAlert(prev => ({...prev,
                    open: true,
                    message: 'No user found',
                    severity: 'info'
                  }))
                  setShow({display: 'none'})
                }
              })
              .catch(err => handleError(err.message));
            } else {
              setLoading(false)
              setAlert(prev => ({...prev,
                open: true,
                message: 'Name or email field is empty',
                severity: 'info'
              }))
            }
            break
          case event.target.id === 'site' || event.target.parentElement.id === 'site':
            if (site) {
              query(`/v2/sites?filter[q]=${site}`, { method: 'GET' })
              .then(res => {
                setLoading(false)
                if (res.items.length) {
                setShow({display: 'block'})
                const info = res.items[0].owner
                const time = new Date(info.registerTime).toLocaleDateString()
                const verified = info.verified.toString()
                setUser({
                  id: info.id,
                  name: info.name,
                  email: info.email,
                  ip: info.registerIp,
                  service: info.registerServiceName,
                  time: time,
                  verified: verified,
                  status: info.status,
                  role: info.role
                })
                if (info.status === 'blocked') {
                  setBlockButton({
                    styles: {
                      backgroundColor: '#ffa700', color: '#fff'
                    },
                    action: unblockUser(info.id),
                    text: 'Unblock user'
                  })
                } else {
                  setBlockButton({
                    styles: {
                      backgroundColor: '#ff0031', color: '#fff'
                    },
                    action: blockUser(info.id),
                    text: 'Block user and delete sites'
                  })
                }
                query('/v1/users/' + info.id + '/sites', { method: 'GET' })
                  .then(result => {
                    if (result.items.length) {
                      const Sites = []
                      for (let i = 0; i < result.items.length; i++) {
                        let license = getLicenseType(result.items[i])
                        Sites.push({
                          id: result.items[i].id,
                          url: result.items[i].publicUrl,
                          license: license
                        })
                      }
                      setSites(Sites)
                      sites.some(x => x.license === 'premium') 
                      ? setUser(prev => ({...prev,
                        type: 'premium'
                      }))
                      : setUser(prev => ({...prev,
                        type: 'free'
                      }))
                    } else {
                      setSites([])
                      setUser(prev => ({...prev,
                        type: 'free'
                      }))
                    }
                  })
                  .catch(err => handleError(err.message));
                } else {
                  setAlert(prev => ({...prev,
                    open: true,
                    message: 'No user found',
                    severity: 'info'
                  }))
                  setShow({display: 'none'})
                }
              })
              .catch(err => handleError(err.message));
            } else {
              setLoading(false)
              setAlert(prev => ({...prev,
                open: true,
                message: 'Site id field is empty',
                severity: 'info'
              }))
            }
            break
        }
    }
  }

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.root}>
            <CircularProgress />
        </Grid>
      </ThemeProvider>
          
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.root}
      >
        <Grid
          container
          direction="row"
          justify="flex-end"
          className={classes.logout}
        >
          <Button variant="contained" size="large" align="right" color="default" className={classes.input} onClick={logOut}>
            Log out
          </Button>
        </Grid>
        <Grid item lg={6}>
          <Snackbar open={alert.open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
            <Alert onClose={handleClose} severity={alert.severity}>
              {alert.message}
            </Alert>
          </Snackbar>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Grid item>
                <div className={classes.text}>
                  <Typography variant="body1">
                    User name or email
                  </Typography>
                </div>
                <TextField InputProps={{
                  className: classes.input
                }} onChange={nameChange} onKeyDown={search} id="name" variant="outlined"/>
                <Button variant="contained" size="large" color="primary" className={classes.input} id="name" onClick={search}>
                  Find
                </Button>
              </Grid>
              <Grid item>
                <div className={classes.text}>
                  <Typography variant="body1">
                    Site id
                  </Typography>
                </div>
                <TextField InputProps={{
                  className: classes.input
                }} onChange={siteChange} onKeyDown={search} id="site" variant="outlined"/>
                <Button variant="contained" size="large" color="secondary" id="site" className={classes.input} onClick={search}>
                  Find
                </Button>
              </Grid>
              <Grid item>
                <Card style={show} className={classes.card}>
                  <Grid
                    container
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item>
                      <CardMedia
                        className={classes.cover}
                        component="img"
                        image={Photo}
                        title="Live from space album cover"
                      />
                    </Grid>
                    <Grid item>
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography variant="subtitle1" color="textPrimary">
                            <div>ID: <b>{user.id}</b></div>
                            <div>Name: <b>{user.name}</b></div>
                            <div>Email: <b>{user.email}</b></div>
                            <div>Register IP: <Link href={`https://whatismyipaddress.com/ip/${user.ip}`} rel="noopener" target="_blank" style={{textDecoration: 'none'}}><b>{user.ip}</b></Link></div>
                            <div>Register Service: <b>{user.service}</b></div>
                            <div>Register Time: <b>{user.time}</b></div>
                            <div>Verified: <b>{user.verified}</b></div>
                          </Typography>
                          <Grid
                            container
                            alignItems="center"
                            direction="row"
                          >
                            <Grid item className={classes.spaced}>
                              <Typography align="center" variant="body2" color="textPrimary">
                                Status
                              </Typography>
                              <Chip label={user.status} className={classes[user.status]} color="primary" size="small" />
                            </Grid>
                            <Grid item className={classes.spaced}>
                              <Typography align="center" variant="body2" color="textPrimary">
                                Role
                              </Typography>
                              <Chip label={user.role} className={classes.free} color="primary" size="small" />
                            </Grid>
                            <Grid item className={classes.spaced}>
                              <Typography align="center" variant="body2" color="textPrimary">
                                Type
                              </Typography>
                              <Chip label={user.type} className={classes[user.type]} size="small" />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid
                container
                alignItems="center"
                direction="row"
                justify="center"
              >
                  {
                    sites.map((item) => (
                      <Grid xs={10} lg={4} xl={3} md={4} item key={item.id} style={show}>
                          <div className={classes.sites}>
                            <Grid container justify="flex-end">
                              <IconButton aria-label="delete" size="small" className={classes.deleteButton} onClick={deleteSite(item.id)}>
                                <Delete />
                              </IconButton>
                            </Grid>
                            <Card style={{padding: '15px'}}>
                              <Link href={item.url} target="_blank" style={{textDecoration: 'none'}}>
                                <Typography variant="body1" align="center" color="textSecondary">
                                  id: {item.id}
                                </Typography>
                                <Typography variant="body1" align="center" style={{wordWrap: 'break-word'}}>
                                  {item.url}
                                </Typography>
                                <div align="center">
                                  <Chip label={item.license} className={classes[item.license]} size="small" />
                                </div>
                              </Link>
                            </Card>
                          </div>
                      </Grid>
                    ))
                  }
              </Grid>
              <Grid item style={show} className={classes.root}>
                <Button variant="contained" size="large" color="default" className={classes.input} onClick={deleteUser(user.id)}>
                  Delete user
                </Button>
                <Button variant="contained" size="large" style={blockButton.styles} className={classes.input} onClick={blockButton.action}>
                  {blockButton.text}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Admin;