/**
 * see mbrServer for additional info
 */
// jshint esversion: 6
class Client {

    constructor(apiUrl) {
        this._apiUrl = apiUrl;
    }

    getXSRFToken() {
        return getCookie('XSRF-TOKEN') || '';
    }

    getApiUrl() {
        return this._apiUrl;
    }

    /**
     * @param {Object} options
     * @param {String} options.url
     * @param {String} options.method
     * @param {Object} options.headers
     * @param {String} options.contentType (only for GET method)
     * @param {Object} options.data - will stringified
     * @return {Promise}
     */
    ajax(options = {}) {
        var method = (options.method || 'GET').toUpperCase();
        if (method === 'POST' || method === 'PUT') {
            options.contentType = 'application/json';
            if ('data' in options) options.data = JSON.stringify(options.data);
        }

        return fetch(options.url, {
                credentials: 'include',
                method: method,
                headers: Object.assign(options.headers || {}, {
                    'X-XSRF-TOKEN': this.getXSRFToken(),
                    'content-type': options.contentType
                }),
                body: options.data
            })
            .then(function(res) {
                return res.text().then(function(data) {
                    try {
                        data = JSON.parse(data);
                    } catch (err) {
                        throw new Error('Incorrect server response.');
                    }
                    if (res.status < 200 || res.status > 299) {
                        throw (Object.assign(
                            new Error(data.message), { code: data.type }
                        ));
                    }
                    return data;
                });
            }, function() {
                throw new Error('The request to the server could not be completed.');
            });
    }
    /**
     * @param {Object} options
     * @param {String} options.email
     * @param {String} options.passsword
     * @return {Promise} = {'XSRF-TOKEN'}
     */
    signin(options) {
        this._profile = 0;

        return this.ajax({
                url: this.getApiUrl() + 'sessions',
                method: 'POST',
                data: {
                    email: options.email,
                    password: options.password
                }
            })
            .then(function(data) {
                if (data && data.XSRFToken) {
                    setCookie('XSRF-TOKEN', data.XSRFToken);
                    return data;
                } else {
                    throw new Error('Incorrect server response.');
                }
            });
    }

    signinByToken({service, token} = {}) {
        this._profile = 0;

        return this.ajax({
            url: this.getApiUrl() + 'sessions',
            method: 'POST',
            data: {service, token}
        }).then((data) => {
            if (data && data.XSRFToken) {
                setCookie('XSRF-TOKEN', data.XSRFToken);
                return data;
            } else {
                throw new Error('Incorrect server response.');
            }
        });
    }

    signup(email) {
        return this.ajax({
            url: this.getApiUrl() + 'users',
            method: 'POST',
            data: {
                email: email
            }
        });
    }

    signout() {
        this._profile = 0;

        return this.ajax({
            url: this.getApiUrl() + 'me/session',
            method: 'DELETE'
        });
    }

    /**
     * @return {Promise} - {beginner:false, email:"account@mail.com", id:"123", verified:true}
     */
    getProfile() {
        var _this = this;

        return new Promise((resolve, reject) => {
            if (_this._profile) {
                resolve(_this._profile);
            } else {
                this.ajax({ url: this.getApiUrl() + 'me', contentType: 'application/json', 'X-XSRF-TOKEN': this.getXSRFToken() }).then(profile => {
                        _this._profile = profile; // no return
                        resolve(profile);
                    })
                    .catch(reject);
            }
        });

    }

    isAuthorized() {
        return new Promise((resolve, reject) => {
            this.getProfile()
                .then(
                    function() {
                        resolve(true);
                    })
                .catch(function(err) {
                    if (err.code === 403) {
                        resolve(false);
                        return;
                    }
                    return reject(err);
                });
        });
    }

    getAuthToken() {
        return this.ajax({
            url: this.getApiUrl() + 'tokens',
            method: 'POST',
            data: {target: 'https://8b.io'}
        }).then((data) => ({access_token: data.value}));
    }

    lostpassword(email) {
        return this.ajax({
            url: this.getApiUrl() + 'requests',
            method: 'POST',
            data: {type: 'PasswordReset', params: {email}}
        });
    }

}

function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value) {
    document.cookie = name + "=" + encodeURIComponent(value);
}

export { Client, setCookie, getCookie }
