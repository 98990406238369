import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from "../components/auth";
import { getCookie } from '../libs/mbr-client';

function PrivateRoute({ component: Component, ...rest }) {
    let { authTokens } = useAuth();

    if (getCookie('XSRF-TOKEN') !== undefined) {
        authTokens = getCookie('XSRF-TOKEN');
    }

    return (
      <Route
        {...rest}
        render={props =>
          authTokens ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />
          )
        }
      />
    );
  }
  
  export default PrivateRoute;