import React, { useState } from 'react';
import Admin from './pages/admin'
import Login from './pages/login'
import { AuthContext } from "./components/auth";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from './components/PrivateRoute';

const App = () => {
  const [authTokens, setAuthTokens] = useState();

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Admin} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;