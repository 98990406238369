import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, CircularProgress, Snackbar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useStyles, theme } from '../assets/styles/style'
import { ReactComponent as Google } from '../assets/images/google.svg';
import { GoogleLogin } from 'react-google-login';
import { Redirect } from "react-router-dom";
import { Client, setCookie } from '../libs/mbr-client.js';
import { Alert } from '@material-ui/lab';

const Login = () => {
  // hooks
  const classes = useStyles();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({});
  const [stage, setStage] = useState();
  const [text, setText] = useState({});
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success',
    vertical: 'top',
    horizontal: 'center'
  });
  const vertical = alert.vertical
  const horizontal = alert.horizontal

  // mbr auth
  const mbrClient = new Client("https://u.8b.com/api/v1/");
  const clientId = '127029165104-0mr0eo7cltaco9bq03lhj3rn03pc8bej.apps.googleusercontent.com'
  const scope = 'openid email https://www.googleapis.com/auth/userinfo.email'

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(prev => ({...prev, open: false }));
  };

  // google login

  const success = (response) => {
    setLoading(true)
    mbrClient.signinByToken({ service: 'google', token: response.accessToken })
      .then(res => {
        setLoading(false)
        setCookie('XSRF-TOKEN', res.XSRFToken);
        setLoggedIn(true);
      })
      .catch(err => {
        setLoading(false)
        setAlert(prev => ({...prev,
          open: true,
          message: err.message,
          severity: 'error'
        }))
      });
  }

  const failed = (response) => {
    console.log('auth failed: ', response.error)
  }

  

  // manage email login

  function handleEmailChange (event) {
    setCredentials({ email: event.target.value })
  }

  function handleEmailAction (event) {
    // eslint-disable-next-line
    switch (event.type) {
      // eslint-disable-next-line
      case 'keydown':
        if (event.keyCode !== 13) break
        // eslint-disable-next-line
      case 'submit':
        event.preventDefault();
        // eslint-disable-next-line
      case 'click':  
        setLoading(true)
        setCookie('language', 'en-US');
        mbrClient
          .signup(credentials.email)
          .then(() => {
            setLoading(false);
            setStage(0);
            setText({
              passwordInfoText: `Check your email. Your account password is sent to ${credentials.email}. \nIf you haven't received the email, please check Spam folder`,
              passwordHeading: '8b Account Created'
            })
          })
          .catch(error => {
            setLoading(false);
            if (error.code === 'UserAlreadyExists') {
              setStage(0);
              setText({
                passwordInfoText: `Your password is sent to ${credentials.email} \nwith the first sign up. Check your email to find it.`,
                passwordHeading: `You already have 8b account for ${credentials.email}`
              })
            } else {
              setAlert(prev => ({...prev,
                open: true,
                message: error.message,
                severity: 'error'
              }))
            }
          });
    }
  }


  // manage password

  function handlePassChange (event) {
    setCredentials(prevState => ({ ...prevState, password: event.target.value }))
  }

  function handlePassAction (event) {
    // eslint-disable-next-line
    switch (event.type) {
      // eslint-disable-next-line
      case 'keydown':
        if (event.keyCode !== 13) break
        // eslint-disable-next-line
      case 'submit':
        event.preventDefault();
        // eslint-disable-next-line
      case 'click':
      setLoading(true)
      setStage(0);
      mbrClient
        .signin(credentials)
        .then((res) => {
          setCookie('XSRF-TOKEN', res.XSRFToken);
          setLoggedIn(true);
          setLoading(false)
          mbrClient
            .getProfile()
            .catch(e => {
              console.log(e)
            }) 
        })
        .catch(err => {
          setLoading(false)
          setAlert(prev => ({...prev,
            open: true,
            message: err.message,
            severity: 'error'
          }))
        });
    }
  }

  if (isLoggedIn) {
    return <Redirect to='/' />;
  }

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.root}>
            <CircularProgress />
        </Grid>
      </ThemeProvider>
          
    );
  }

  switch (stage) {
    case 0:
      return (
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.root}>
            <Grid item xs={6} lg={3} align="center">
              <Snackbar open={alert.open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
                <Alert onClose={handleClose} severity={alert.severity}>
                  {alert.message}
                </Alert>
              </Snackbar>
              <Grid
                container
                direction="column">
                <form className={classes.root} noValidate autoComplete="off">
                  <Typography className={classes.heading} align="center" variant="h4">
                    {text.passwordHeading}
                  </Typography>

                  <TextField type="password" id="password" onChange={handlePassChange} onKeyDown={handlePassAction} size="medium" label="Enter Password" variant="outlined"/>

                  <Typography className={classes.heading} align="center" variant="body1">
                    {text.passwordInfoText}
                  </Typography>

                  <Button variant="contained" size="medium" color="default" className={classes.input} onClick={handlePassAction}>
                      Next
                  </Button>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      );
    default:
      return (
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.root}>
            <Snackbar open={alert.open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
              <Alert onClose={handleClose} severity={alert.severity}>
                {alert.message}
              </Alert>
            </Snackbar>
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleEmailAction}>
              <Grid
                container
                direction="column">
                <Typography className={classes.heading} align="center" variant="h4">
                    Login/Signup
                </Typography>

                <GoogleLogin
                    clientId={clientId}
                    scope={scope}
                    render={renderProps => (
                        <Button variant="contained" size="large" color="secondary" startIcon={<Google />} className={classes.google} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                            Login with Google
                        </Button>
                    )}
                    onSuccess={success}
                    onFailure={failed}
                    cookiePolicy={'single_host_origin'}
                />

                <Typography className={classes.heading} align="center" variant="body1">
                    or
                </Typography>

                <TextField id="email" onChange={handleEmailChange} onKeyDown={handleEmailAction} size="medium" label="Enter Email" variant="outlined"/>

                <Button variant="contained" size="medium" color="default" className={classes.input} onClick={handleEmailAction}>
                    Next
                </Button>
              </Grid>
            </form>
          </Grid>
        </ThemeProvider>
      );
  }
}

export default Login;