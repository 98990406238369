import { Client } from '../libs/mbr-client';
import axios from 'axios';

const mbrClient = new Client("https://u.8b.com/api/v1/");
const API_URL = 'https://api.8b.io';

let TOKEN = '';

function query(path, options) {
    if (!path.startsWith('/')) path ='/' + path;
    options = options || {};
    
    let method = (options.method || 'GET').toUpperCase();
    if (method === 'POST' || method === 'PUT') {
        options.contentType = 'application/json';
        if ('data' in options) options.data = JSON.stringify(options.data);
    }

    function update_token() {
        return mbrClient.getAuthToken()
            .then(token => TOKEN = token.access_token);
    }
    
    function run_query() {
        return axios(API_URL + path,
            {
                method: method,
                headers: Object.assign(options.headers || {},
                    { 'Authorization': 'bearer ' + TOKEN, 'Content-type': options.contentType })
            });
    }
    
    return Promise.resolve()
        .then(() => {
            if (!TOKEN)
                return update_token()
        })
        .then(run_query)
        // try to update token
        .catch(function(error){
            if (error.type === 'TokenExpired') {
                return update_token()
                    .then(run_query)
            }
            else
                throw error;
        })
        .then(res => res)
        .then(function(data) {
            if (data.error)
                throw data.error.message || data.error;
            
            return data.data;
        });
}

export default query;